<template>
    <div class="goods-info">
        <div class="top">
            <img :src="imgUrl"/>
           <!--  <div class="price">
                <p>当前价</p>
                <p>48/kg</p>
            </div> -->
        </div>
        <div class="desc">
            <p class="price">56.00元/箱<span>之前买过，再来一单</span></p>
            <p class="title">陕西周至猕猴桃3.8-4斤装（中果-单果100克以上）</p>
            <p class="content">产品描述：果形整齐、酸甜适口，口感浓郁，果肉翠绿，质地细、汁多，口感酸甜，有香气，适口性强VC及氨基酸的含量高。</p>
            <p class="tips">包邮，预计48小时发货</p>
        </div>
        <div class="content-bottom">
            <p class="title">配送详情</p>
            <p class="content">预计2-4天内送达，第三方物流配送</p>
        </div>
        <van-goods-action>
            <van-goods-action-icon icon="chat-o" text="客服"/>
            <van-goods-action-icon icon="cart-o" text="购物车"/>
            <van-goods-action-icon icon="shop-o" text="店铺"  />
            <van-goods-action-button type="warning" text="加入购物车" @click="goCar"/>
            <van-goods-action-button type="danger" text="立即购买" @click="goCar" />
        </van-goods-action>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgUrl: require('@/assets/planA/orange.png')
        }
    },
    methods: {
        goCar() {
            this.$router.push({name: 'shoppingCar'})
        }
    }
}
</script>

<style lang="scss" scoped>
    .goods-info {
        .top {
            position: relative;
            width: 100%;
            height: 375px;
            img {
                width: 100%;
                height: auto;
            }
            /* .price {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 60px;
                box-sizing: border-box;
                padding: 4px;
                background: url(~@/assets/planA/glass.png) no-repeat;
                background-size: 100%;
                p:nth-of-type(1) {
                    padding-left: 20px;
                    font-size: 12px;
                }
                p:nth-of-type(2) {
                    padding-left: 20px;
                    font-size: 20px;
                }
            } */
        }
        .desc {
            width: 357px;
            box-sizing: border-box;
            margin: 0 auto;
            margin-top: 10px;
            padding: 14px;
            border-radius: 12px;
            background-color: #fff;
            .price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 20px;
                color: #F35800;
                line-height: 28px;
                span {
                    display: inline-block;
                    width: 133px;
                    height: 25px;
                    font-size: 12px;
                    color: #fff;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 12px;
                    background: linear-gradient(180deg, #FF8805 0%, #FE5002 100%);
                }
            }
            .title {
                margin-top: 14px;
                font-size: 16px;
                color: #1C1C1C;
            }
            .content {
                margin-top: 10px;
                font-size: 14px;
                color: #B2B2B2;
            }
            .tips {
                margin-top: 12px;
                font-size: 12px;
                color: #8A8A8A;
            }
        }
        .content-bottom {
            width: 357px;
            box-sizing: border-box;
            margin: 0 auto;
            margin-top: 10px;
            padding: 14px;
            border-radius: 12px;
            background-color: #fff;
            .title {
                font-size: 14px;
                color: #343434;
            }
            .content {
                margin-top: 8px;
                font-size: 14px;
                color: #343434;
            }
        }
    }
</style>